
































































import { Component } from 'vue-property-decorator';
import {
  dispatchCreatePackage, dispatchGetPanels, dispatchGetPanelsForList,
} from '@/store/crud/actions';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICreatePackage, IPackage } from '@/interfaces/packages';

@Component({})
export default class CreatePackage extends AppComponent {
  public valid = false;
  /*
    package data
  */
  public name: string = '';
  public version: string = '';
  public description: string = '';

  private selectedPanels: string[] = []; // panels selected by user


  /**
   * on mounted hook
   * it calls initScreen for async configuration
   */
  public mounted() {
    this.initScreen();
  }

  /**
   * it starts the screen by doing requests
   * to server to feed the view
   */
  public async initScreen() {
    this.setAppLoading(true);
    await dispatchGetPanelsForList(this.$store);
    this.reset();
    this.setAppLoading(false);
  }

  /**
   * it reset the form
   */
  public reset() {
    this.name = '';
    this.version = '';
    this.description = '';
    this.selectedPanels = [];
    this.$validator.reset();
  }

  /**
   * Cancels the operation and goes back to admin panels
   */
  public cancel() {
    this.$router.back();
  }

  /**
   * it maps the data from the component into the
   * IPackage interface and calls the create package action
   * resulting in a submit to the server
   */
  public async submit() {
    if (await this.$validator.validateAll()) {
      const protoPackage: IPackage = {};

      if (!this.hasValue(this.name)) {
        this.toast('Name is required', true);
        return;
      }
      if (this.hasValue(this.name)) { protoPackage.name = this.name; }
      if (this.hasValue(this.version)) { protoPackage.version = this.version; }
      if (this.hasValue(this.description)) { protoPackage.description = this.description; }
      if (this.hasValue(this.selectedPanels)) {
        protoPackage.panels = [] as string [];
        this.selectedPanels.forEach((panel) => {
          if (panel && panel.length > 0) {
            protoPackage.panels!.push(panel);
          }
        });
       }
      const packageCreate: ICreatePackage = protoPackage as ICreatePackage;
      await dispatchCreatePackage(this.$store, packageCreate);
      this.$router.push('/main/content/packages');
    }
  }
}
